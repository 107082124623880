<template>
  <v-container fluid>
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
      <opciones />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3>CARGA MASIVA</h3>
    </v-app-bar>
    <v-container elevation="10" fluid>
      <lclComponent />
    </v-container>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    opciones: () => import("@/components/comun/opciones.vue"),
    lclComponent: () => import("../../components/Calculadora/lclComponet.vue"),
  },
  data() {
    return {
      mostrar: false,
    };
  },
  mounted() {
    let opciones = [
      {
        id: 1,
        nombre: "Carga Masiva",
        class: "active",
        url: "carga_masiva",
      },
      {
        id: 1,
        nombre: "USUARIOS CALC",
        // class: "active",
        url: "list_user_calc",
      },
      // {
      //   id: 2,
      //   nombre: "FCL",
      //   url: "carga_masiva_fcl",
      // },
      // {
      //   id: 3,
      //   nombre: "AÉREO",
      //   url: "reporte_abierta",
      // },
    ];
    this.opcioneMenuLista(opciones);
  },
  computed: {
    ...mapState([""]),
  },
  methods: {
    ...mapMutations(["opcioneMenuLista"]),
  },
};
</script>

<style></style>
